<template>
  <array-min-max
    :uuid="uuid"
    actionID="16"
    i18nKey="array_max"
    :middlewareID="middlewareID"
    :agentID="agentID"
    v-model="agent"
    ref="array-min-max-component"
    @saved="onSaved"
    :agentBlockID="agentBlockID"
    @agentClosed="$emit('agentClosed')"
  >

    <template #icon>
      <div class="d-inline">
        <div class="text-center text-secondary"> <small>{{ $t(`agent.list.${i18nKey}.register_1.title`).toLowerCase() }} </small></div>
        <b-icon scale="2" icon="dice2" class="mx-1" shift-v="-10" />
        <b-icon scale="2" icon="dice3" class="mx-1" shift-v="-10" />
        <b-icon scale="2" icon="dice1" class="mx-1" shift-v="-10" />
        <b-icon scale="2" icon="dice5" class="mx-1" shift-v="-10" variant="warning"/>
        <b-icon scale="2" icon="dice4" class="mx-1" shift-v="-10" />
      </div>
      <div>
        <b-icon scale="2" icon="arrow-right-short" class="mx-1 mt-2" shift-v="-15" />
      </div>
      <div class="mt-1">
        <span class="d-block mt-1 text-success"><big><b>{{ $t(`agent.list.${i18nKey}.icon_value`)}}</b></big></span>
      </div>
      <div>
        <b-icon scale="2" icon="arrow-right-short" class="mx-1 mt-2" shift-v="-15" />
      </div>
      <div class="d-inline">
        <div class="text-center text-secondary"> <small>{{$t('common.terms.result')}} </small></div>
        <b-icon scale="2" icon="dice5" class="mx-1" shift-v="-10" variant="success" />
      </div>
    </template>


  </array-min-max>
</template>

<script>

import ArrayMinMax from './ArrayMinMax.vue';
import NormalizedAgent from "@/custom/class/NormalizedAgent.js";


  import AgentsMixin from '../AgentsMixin'

export default {
  mixins: [AgentsMixin],

    data(){
      return{
        i18nKey : "array_max"
      }
    },
    components: {
      ArrayMinMax,
      NormalizedAgent,
    },
    props: {
      middlewareID: {
        type: Number,
        required: true,
      },
      agentID: {
        type: Number,
        default: undefined,
      },
      value: {
        type: NormalizedAgent,
        default: undefined
      },
      uuid: {
        type: String,
        required: true,
      },
      width: {
        type: String,
        default: "40%"
      },
      agentBlockID: {
        type: [ Number, String ],
        default: 1,
      },
      variables:{
        type: Object,
        default: undefined
      }
    },
    computed: {
      agent: {
        get() {
          return this.value
        },
        set(value) {
          this.$emit('input', value)
        }
      },
      toSaveRegs(){
        return this.$refs['array-min-max-component'].toSaveRegs
      }

    },
    methods: {
      onSaved(payload) {
        this.$emit('saved', payload)
      }
    }
  }
</script>

<style lang="scss" scoped>

</style>