export default class HelperOptions {

  constructor(innerHTML, modalTitle = 'Helper', size = 'md', buttonVariant = 'info') {
    this.innerHTML = innerHTML;
    this.modalTitle = modalTitle;
    this.size = size;
    this.buttonVariant = buttonVariant;
  }

  getInnerHTML() {
    return this.innerHTML;
  }

  getModalTitle() {
    return this.modalTitle;
  }

  getSize() {
    return this.size;
  }

  getButtonVariant() {
    return this.buttonVariant;
  }
}